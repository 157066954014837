
function Story() {
  return (
    <div className="story-component">
      <h2>How it all started</h2>
      <p>
      The idea for Web3 Sweden was born on the conference floor during the European Blockchain Conference in Barcelona in the fall of 2023. After two days of intense Web3 discussions with people from all over Europe, it struck me that I hadn't met a single Swedish company or individual on site. Meanwhile, communities from other countries were represented. The Dutch community, for instance, was active, organizing side events and encouraging networking. It became clear that Sweden needed a corresponding community to connect stakeholders in the field, promote dialogue, education, and collaboration. Thus, Web3 Sweden was born.
      </p>
    </div>
  );
}

export default Story;
