function Hero() {

  const scrollToSection = () => {
    window.scrollTo({
      top: document.getElementById('events').offsetTop,
      behavior: 'smooth'
    });
  }

  return (
    <div className="hero-component">
      <div className="hero-component-content">

      
      <h1>We’re connecting the Swedish Web3 community</h1>
      {/* <img src="/groupwork.png" alt="An image of a team high fiveing." /> */}
      <p>
        By connecting and bringing people together Web3 want to be an inclusive,
        positive and collaborative force for the adoption of web3 innovation in
        Sweden. Connect, learn, grow and win, together.
      </p>
      <div className="btn-div">
      <a href="https://www.linkedin.com/groups/12924312/" target="_blank"><button id="join-btn">Join Community</button></a>
        
        <button onClick={scrollToSection} id="event-btn">Events</button>
      </div>
      {/* <div className="img-container" >
      
      <img src="/cropped.jpg" alt="A picture of David & Hugo" />
      </div> */}
      
      
      </div>
      
    </div>
  );
}

export default Hero;
