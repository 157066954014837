import React, { useState } from "react";

const FAQ = () => {
  const faqs = [
    {
      question: "What Happened Next?",
      answer: "An intensive search to identify individuals, companies, and organizations connected to Web3 in Sweden began. LinkedIn became the logical platform to gather the community, and many have received an invitation to connect and join the community. The community's founding principles have been to be open, inclusive, and work towards a positive development for individuals, businesses, and society at large. Achieving this requires collaboration between the private sector, academia, and public institutions. It also necessitates education, a better understanding of the development and growth opportunities Web3 brings, and opportunities for people to meet, connect, and exchange experiences. The community should also be fun, energizing, and inspiring for its participants. Meeting and talking to like-minded, engaged, and curious Web3 enthusiasts has been a central point. On December 13, 2023, the community gathered live for the first time at Saluhallen in Malmö. It became clear that there was a great need to meet and learn from each other. The first meeting in Malmö has been followed by meetings in Gothenburg and Stockholm, confirming that Web3 interest is strong and rapidly growing."
    },
    {
      question: "What's Next for Web3 Sweden?",
      answer: "Although the foundations of Web3 started with the birth of Bitcoin and blockchain in the aftermath of the 2008 financial crisis, Web3 is still a young concept that many are not familiar with and even fewer understand how its development will shape society in the future. Educating and informing about the development and its opportunities will continue to be a priority, as education and increased understanding are prerequisites for Sweden to be a leading innovative country in this megatrend. The approach will mainly be through educational efforts, networking events, and partnerships with the private sector, academia, and public institutions. Helping today's leading Web2 companies maintain their competitiveness during the transition to Web3 will be of great national importance for Sweden. Expanding the reach of education and networking events beyond the country's three largest cities would be desirable for Web3 Sweden. Another future possibility for Web3 Sweden is to act as a testbed and hub for how various Web3 technologies and concepts can be applied in society."
    },
    {
      question: "What Is the Idea Behind the Name, Web3 Sweden?",
      answer: "Web3 is a global transformation, similar to the development of AI. The market, growth in the field, and access to talent will extend far beyond Sweden's borders. If we want Sweden to maintain its innovative leadership, we must attract resources and talent from outside as well, hence the choice of English as the language of communication."
    },
    {
      question: "What Is Sweden's Current Position in Web3?",
      answer: "Sweden is regularly highlighted as the world's second most innovative country, just after Switzerland, but within Web3, Sweden is lagging significantly behind the rest of the world. The reasons for falling behind are likely multiple. Sweden has long had a well-developed infrastructure in many societal areas, not least the banking system, which has meant that the need for change has not been sufficiently pressing. We face regulatory challenges in the area that hinder innovation and risk-taking. A negative, and predominantly incorrect, narrative around cryptocurrencies in Sweden has cast a pall over the industry, limiting and stopping development and innovation in the field. Information and education about the possibilities need to have a greater impact for companies to dare to experiment, innovate, and invest in the field, thus preventing Sweden from falling behind."
    },
    {
      question: "What Is Needed for Web3 Sweden to Continue Promoting Innovation and Development Within Web3 in the Country?",
      answer: "Most important is financial support. Building the network into the gathering point for Web3 in the country that it is today has been done entirely pro bono, based on engagement and a belief that Web3 is a field, a megatrend, that creates opportunities for Swedish companies and society if we embrace it, but also creates significant risks if we fall behind in its development. To continue operating and developing, funding is needed. Networking, collaboration, and partnerships are other crucial aspects for us to disseminate information and educate companies and public institutions, as well as ensuring we have properly trained talent in the field where academia plays an important role. Access to the network and collaboration with various stakeholders in the field will be crucial for positioning advantageously."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className="faq-question"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
            </button>
            {openIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
