import EventCard from "../EventCard/EventCard";

function Events() {
  return (
    <div id="events" className="events-component">
      <h2>Our Previous Events</h2>
      <div className="events">
        <EventCard
          img="/img/Orginal/13-dec.jpg"
          name="Malmö 13 Dec"
          text="A small but dedicated group of enthusiasts gathered for Web3 Sweden's first meetup at Saluhallen in Malmö on Lucia (2023)."
        />
        <EventCard
          img="/img/Orginal/25-jan.jpg"
          name="Göteborg 25 Jan"
          text="Our second meetup, held at EY's office in Gothenburg, had around twenty curious attendees. Zara Zamani talked about Neoki and their development of a Multi-Metaverse, a Web3 marketplace, AI-based avatars, and Digital wardrobes. Magnus Jones followed up with an in-depth presentation on the EU's new regulation for digital assets, MiCA, and a look back at how Web3 has developed since 2015."
        />
        <EventCard
          img="/img/Cropped/29-feb.jpg"
          name="Malmö 29 Feb"
          text="On February 29th, it was time for another meetup in Malmö. This time, ArtScape with Tor Hedendahl and Daniel Wakeham hosted the event, while Jonas Stiebar-Bang and Concordium sponsored with pizza and drinks."
        />
        <EventCard
          img="/img/Orginal/29-mar.jpg"
          name="Stockholm 29 Mar"
          text="On March 12th, it was finally time to meet the community in Stockholm, and what an attendance it was. Around 60-70 people squeezed into SNASKS and Punch’s cool venues, where, in addition to Daniel Dahlström (Punch), Mateo Florez from Trust Anchor Group and Johannes Norrbacka from Monetax gave interesting presentations."
        />
      </div>
    </div>
  );
}

export default Events;
