import React, { useState, useEffect } from "react";

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Kontrollera om e-post redan är sparad i localStorage
    const savedEmail = localStorage.getItem("newsletterEmail");

    if (!savedEmail) {
      // Om ingen e-post finns sparad, visa popup efter 3 sekunder
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 3000);

      // Rensa timeouten om komponenten unmountas
      return () => clearTimeout(timer);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Spara e-postadressen i localStorage
    localStorage.setItem("newsletterEmail", email);
    console.log("Email submitted and saved:", email);
    setIsVisible(false); // Stänger popupen efter att e-posten har sparats
  };

  if (!isVisible) return null; // Visa inte popupen om den inte är synlig

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Subscribe to our newsletter</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Subscribe</button>
        </form>
        <button className="close-btn" onClick={() => setIsVisible(false)}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
