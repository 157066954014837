import './App.scss';
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero'
import Sponsors from "./components/Sponsors/Sponsors";
import Story from './components/Story/Story';
import Events from './components/Events/Events';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Popup from './components/Popup/Popup';
import FAQ from './components/FAQ/FAQ';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <div className='wrapper'>
      <Hero></Hero>
      <Sponsors></Sponsors>
      <Story></Story>
      <Events></Events>
      <FAQ></FAQ>
      <Footer></Footer>
      <Popup></Popup>
      </div>
      
    </div>
  );
}

export default App;
