const EventCard = ({ text, name, img }) => {
    return (
      <div className="card">
        <img src={img} alt={name} className="card-img" />
        <div className="card-content">
          <h3>{name}</h3>
          <p className="card-text">{text}</p>
        </div>
      </div>
    );
  };
  
  export default EventCard;