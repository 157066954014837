import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { requestAccount } from "../../services/web3Services";

function Header() {
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [installedWallet, setInstalledWallet] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setIsConnected(true);
        } else {
          setIsConnected(false);
          setWalletAddress(null);
        }
      });
      setInstalledWallet(true);
    }
  }, []);

  const handleConnect = async () => {
    if (window.ethereum) {
      try {
        console.log("Requesting accounts...");
        
        const account = await requestAccount()
        setWalletAddress(account);
        setIsConnected(true);
      } catch (error) {
        console.error("User rejected the connection request");
      }
    } else {
      alert("MetaMask is not installed. Please install it to use this app.");
    }
  };

  const handleMenuToggle = () => {
    setMenuVisible((prev) => !prev);
  };

  return (
    <header className="header">
      <div className="header-box">
        <img src="/Web3se.PNG" alt="" className="logo" />

        <div className="btn-div">
          <nav className="header-nav">
            <ul>
              <li>Home</li>
              <li>About</li>
              <li>Services</li>
              <li>Contact</li>
            </ul>
          </nav>
          {installedWallet && (
            <button className="connect-btn" onClick={handleConnect}>
              <img
                src="/metamask.png"
                alt="MetaMask by Icon Mafia on IconScout"
              />
              <span>Connect</span>
            </button>
          )}
          <button className="menu-btn" onClick={handleMenuToggle}>
            <img
              src={menuVisible ? "/remove.png" : "/menu.png"}
              alt="Menu icon"
            />
          </button>
        </div>
      </div>
      {walletAddress && <p>Du är inloggad med kontot {walletAddress}</p>}
      <div className={`dropdown-menu ${menuVisible ? "show" : "hide"}`}>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
