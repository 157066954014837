import { BrowserProvider, parseEther, formatEther } from "ethers";

let provider;
let signer;

const initialize = async () => {
    console.log("initializing");
    
  if (typeof window.ethereum !== "undefined") {
    provider = new BrowserProvider(window.ethereum);
    signer = await provider.getSigner();
  } else {
    console.error("Please install Metamask!");
  }
};

initialize();

export const requestAccount = async () => {
  try {
    const accounts = await provider.send("eth_requestAccounts", []);
    return accounts[0];
  } catch (error) {
    console.error("Error requesting account:", error.message);
    return null
  }
};
