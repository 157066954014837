function Footer() {
    return (
      <footer>
        <div>
            <h3>Our Story</h3>
            <p>Who we are</p>
            <p>About us</p>
        </div>
        <div>
            <h3>The future web</h3>
            <p>Web3</p>
            <p>Possibilities</p>
        </div>
        <div>
            <h3>Support</h3>
            <p>davidgbergling@gmail.com</p>
            <p>072-888-9203</p>
        </div>
        <div>
            <h3>Join the community</h3>
            <p><a href="">Web3 Sweden</a></p>
        </div>

        <p><a href="https://www.flaticon.com/free-icons/minus" title="minus icons">Minus icons created by Becris - Flaticon</a></p>
      </footer>
    );
  }
  
  export default Footer;
  